<template>
  <section class="rules-page">
    <!-- header -->
    <PcHeader v-if="isPc" select="4"></PcHeader>
    <H5Header v-else select="4"></H5Header>

    <!-- banner  -->
    <div class="posters">
      <img
        width="100%"
        :src="
          isPc
            ? require('@/assets/image/hodgepodge/pcbanner.png')
            : require('@/assets/image/hodgepodge/h5banner.png')
        "
      />
    </div>

    <div class="rules-wrapper">
      <div class="list-wrap">
        <div class="animate__animated animate__bounceInLeft">
          <div
            v-for="(item, index) in rulesList"
            class="item-box"
            :key="index"
            @click="openPdf(item.url)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PcHeader from "@/components/header/pc_header";
import H5Header from "@/components/header/h_header";
import publics from "@/mixin/public_mixin";
export default {
  name: "signup",
  components: {
    PcHeader,
    H5Header,
  },
  data() {
    return {
    };
  },
  mixins: [publics],
  computed: {
    isPc() {
      return this.$store.state.isPc;
    },
  },
  methods: {
    openPdf(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .posters {
    display: flex;
    width: 100%;
  }

  .rules-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);

    .list-wrap {
      width: 100%;
      max-width: 1024px;
      box-sizing: border-box;

      .item-box {
        width: 90%;
        max-width: 640px;
        height: 124px;
        margin: 20px auto;
        background: #fff8de;
        box-shadow: 0px 0px 4px 0px rgba(126, 56, 0, 0.26);
        border: 4px solid #fff0e1;
        border-radius: 16px;
        font-size: 24px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff782f;
        line-height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
</style>
